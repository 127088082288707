import clsx from 'clsx'
import { InputHTMLAttributes, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { containsOnlyDigits } from 'utils/string'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	labelText?: string
	name: string
	index?: string
	register?: UseFormRegister<any>
	errors?: any
	className?: string
	type?: string
	step?: string
}

export const Input = ({
	labelText,
	index,
	name,
	register,
	errors,
	step,
	className,
	type,
	...props
}: InputProps) => {
	const errorText = step
		? (errors?.[step]?.[name]?.message as string)
		: (errors?.[name]?.message as string)

	return (
		<div className="flex flex-col gap-y-2">
			{labelText && (
				<label className="text-ellipsis whitespace-nowrap overflow-clip" htmlFor={name}>
					{labelText}
				</label>
			)}
			<input
				{...props}
				{...(register?.(name) ?? {})}
				type={type}
				id={index}
				className={clsx(
					'w-full appearance-none py-3 font-normal border-none bg-gray-100 focus:bg-gray-200 focus:ring-0 text-gray-800 outline-none placeholder-gray-400 rounded-lg focus:outline-none border-gray-500 text-md',
					className,
					{
						'pointer-events-none': props.disabled
					}
				)}
			/>
			{errorText && <p className="text-xs text-red-500">{errorText}</p>}
			<div />
		</div>
	)
}

export const InputNumber = ({
	labelText,
	name,
	placeholder,
	maxLength,
	errors,
	value,
	step,
	onChange,
	className,
	...props
}: InputProps) => {
	const errorText = step
		? (errors?.[step]?.[name]?.message as string)
		: (errors?.[name]?.message as string)

	const [input, setInput] = useState<string>(value?.toString() ?? '')

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target

		if (!maxLength || value.length <= maxLength) {
			if (containsOnlyDigits(value)) {
				setInput(value)
				onChange?.(event)
			}
			if (value == '') {
				setInput(value)
			}
		}
	}

	return (
		<div className="flex flex-col gap-y-2">
			{labelText && (
				<label className="text-ellipsis whitespace-nowrap overflow-clip" htmlFor={name}>
					{labelText}
				</label>
			)}
			<input
				{...props}
				onChange={handleInputChange}
				value={input}
				inputMode="numeric"
				type="text"
				className={clsx(
					'w-full appearance-none font-normal py-3 border-none bg-gray-100 focus:bg-gray-200 focus:ring-0 text-gray-800 outline-none rounded-lg focus:outline-none text-md',
					className
				)}
			/>
			{errorText && <p className="text-xs text-red-500">{errorText}</p>}
			<div />
		</div>
	)
}
