import clsx from 'clsx'
import { UseFormRegister } from 'react-hook-form'

interface SelectProps
	extends React.DetailedHTMLProps<
		React.SelectHTMLAttributes<HTMLSelectElement>,
		HTMLSelectElement
	> {
	children: React.ReactNode
	labelText?: string
	step?: string
	name: string
	register?: UseFormRegister<any>
	errors?: any
}

export const Select: React.FC<SelectProps> = ({
	labelText,
	children,
	register,
	onChange,
	value,
	step,
	errors,
	name,
	className,
	...restProp
}) => {
	const errorText = step
		? (errors?.[step]?.[name]?.message as string)
		: (errors?.[name]?.message as string)

	return (
		<div className="flex flex-col gap-y-2">
			{labelText && (
				<label className="text-ellipsis whitespace-nowrap overflow-clip" htmlFor={name}>
					{labelText}
				</label>
			)}
			<select
				{...restProp}
				{...(register?.(name) ?? {})}
				value={value}
				onChange={onChange}
				className={clsx(
					'w-full text-ellipsis rounded-lg py-3 font-normal border-none bg-gray-100 focus:bg-gray-200 focus:ring-0 text-gray-800 outline-none appearance-none',
					className
				)}>
				{children}
			</select>
			{errorText && <p className="text-red-500 text-xs">{errorText} </p>}
		</div>
	)
}
