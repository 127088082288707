import {
	Control,
	FieldErrors,
	UseFormRegister,
	UseFormResetField,
	UseFormSetValue,
	UseFormWatch
} from 'react-hook-form'

export const Password = '12345'

export interface FormProps {
	register?: UseFormRegister<HealthCornerForm>
	errors?: FieldErrors<HealthCornerForm>
	control?: Control<HealthCornerForm, any>
	setValue?: UseFormSetValue<HealthCornerForm>
	resetField?: UseFormResetField<HealthCornerForm>
	watch?: UseFormWatch<HealthCornerForm>
}
