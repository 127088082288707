import one from 'assets/pngs/1.png'
import two from 'assets/pngs/2.png'
import three from 'assets/pngs/3.png'
import four from 'assets/pngs/4.png'
import five from 'assets/pngs/5.png'

export const OneIcon = one
export const TwoIcon = two
export const ThreeIcon = three
export const FourIcon = four
export const FiveIcon = five
