interface AppHeaderProps {
	heading: string
	number: number
	children: React.ReactNode
}

export const AppHeader = ({ heading, children, number }: AppHeaderProps) => {
	return (
		<header>
			<nav>
				<div className="flex flex-col gap-16">
					<h1 className="font-extrabold lg:text-3xl text-xl p-4 pl-32">
						{number}. {heading}
					</h1>
					{children}
				</div>
			</nav>
		</header>
	)
}
