import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify'

interface SignatureProps {
	setSignature: React.Dispatch<
		React.SetStateAction<{
			afrim: string
			martin: string
			customer1: string
			customer2: string
		}>
	>
	name: 'afrim' | 'martin' | 'customer1' | 'customer2'
	width?: number
	height?: number
}

export const Signature = ({ setSignature, name, width, height }: SignatureProps) => {
	const signatureRef = useRef<ReactSignatureCanvas>({} as ReactSignatureCanvas)
	const [isDrawing, setIsDrawing] = useState(false)

	const clearSignature = () => {
		setIsDrawing(false)
		signatureRef?.current.clear()
		setSignature(prev => ({ ...prev, [name]: '' }))
	}

	const saveSignature = () => {
		const signatureDataURL = signatureRef.current.toDataURL()
		setSignature(prev => ({ ...prev, [name]: signatureDataURL }))

		toast.success('Signature set successfully')
	}

	const handleBeginDrawing = () => {
		setIsDrawing(true)
	}

	return (
		<div className="relative flex items-end justify-end w-fit">
			<ReactSignatureCanvas
				ref={signatureRef}
				onBegin={handleBeginDrawing}
				canvasProps={{
					width: width ? width : 384,
					height: height ? height : 80,
					className: 'border-b border-black'
				}}
				penColor="black"
				minWidth={2}
				maxWidth={3}
			/>
			<div className="absolute flex items-center mb-4 gap-x-2">
				{isDrawing && <CheckIcon onClick={saveSignature} className="h-5 w-5 stroke-green-500" />}

				{isDrawing && <XMarkIcon onClick={clearSignature} className="h-5 w-5 stroke-red-500" />}
			</div>
		</div>
	)
}
