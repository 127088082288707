import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import formService from 'services/form-service'

export const form = createAsyncThunk('form/sendData', async (data: HealthCornerForm, thunkAPI) => {
	try {
		const response = await formService.sendForm(data)
		if (response) {
			toast.success('Form data saved successfully')
			return thunkAPI.fulfillWithValue(data)
		}
		return thunkAPI.rejectWithValue(false)
	} catch (error: any) {
		return thunkAPI.rejectWithValue(false)
	}
})

const initialState = {} as HealthCornerForm

const formSlice = createSlice({
	name: 'form',
	initialState: initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(
			form.fulfilled,
			(state: HealthCornerForm, action: PayloadAction<HealthCornerForm>) => {
				const data = action.payload
				return data
			}
		),
			builder.addCase(form.rejected, (state: HealthCornerForm, action: PayloadAction<any>) => {
				return initialState
			})
	}
})

const { reducer } = formSlice

export default reducer
