import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { HealthCornerForm } from 'pages/healthcorner-form/form'

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HealthCornerForm />} />
			</Routes>
		</BrowserRouter>
	)
}

export default Routing
