import { ArrowDownOnSquareIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useForm } from 'react-hook-form'
import { ProgressBar, Step } from 'react-step-progress-bar'

import { yupResolver } from '@hookform/resolvers/yup'
import cond from 'cond-construct'
import * as yup from 'yup'

import { OneIcon, ThreeIcon, TwoIcon } from 'assets/icons'
import { Button } from 'components/app/button'
import { CompleteForm } from 'components/app/form/complete'
import { ContractForm } from 'components/app/form/contract'
import { CustomerForm } from 'components/app/form/customer-form'
import { OrderForm } from 'components/app/form/order-form'
import { PasswordScreen } from 'components/app/form/password'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import FileUpload from 'components/app/upload'
import { ContractDocument } from 'components/pdf/contract-document'
import { Password } from 'constants/constants'
import { useAppDispatch } from 'hooks'
import { form } from 'slices/form-slice'

enum FormSteps {
	CUSTOMER = 1,
	ORDER,
	CONTRACT,
	COMPLETE
}

type ProgressBarProps = {
	accomplished: boolean
}

type StateType = {
	step: FormSteps
	displayExitModal: boolean
}

export const HealthCornerForm = () => {
	const dispatch = useAppDispatch()

	const schema = yup.object<HealthCornerForm>().shape({
		customerForm: yup.object({
			rechtsform: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Es ist eine Rechtsform erforderlich')
			}),
			welche: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema.test('welcheType', 'Was erforderlich ist', function (value) {
						const rechtsform = this.parent.rechtsform
						if (rechtsform === 'andere' && !value) return false
						return true
					})
			}),
			hrRegister: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('HR-Register ist erforderlich')
			}),
			uidCheMwstNr: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('uid ist erforderlich')
			}),
			furArztpraxis: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Für die ärztliche Tätigkeit erforderlich')
			}),
			gLNBetrieb: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('GLN-Betrieb ist erforderlich')
			}),
			nameDerFirma: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Firmenname ist erforderlich')
			}),
			nameVorname: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Vorname Name ist erforderlich')
			}),
			vornameZweiterVorname: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Vorname Name ist erforderlich')
			}),
			nameUndNachname: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Vorname Name ist erforderlich')
			}),
			eMail: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema
						.required('E-Mail ist erforderlich')
						.email('Geben Sie eine gültige E-Mail-Adresse ein')
			}),
			festnetznummer: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Festnetznummer ist erforderlich')
			}),
			mobile: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Mobil ist erforderlich')
			}),
			strasseNr: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('Straße, Nr. ist erforderlich')
			}),
			plzOrt: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.required('PLZ/Stadt ist erforderlich')
			}),
			webseiteOne: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.notRequired()
			}),
			webseiteTwo: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.notRequired()
			}),
			heard: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.notRequired()
			}),
			remarks: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema => schema.notRequired()
			}),
			pGeburtsdatum: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema.test('pGeburtsdatumType', 'Geburtsdatum ist erforderlich', function (value) {
						const rechtsform = this.parent.rechtsform
						if (rechtsform === 'einzelfirma' && !value) return false
						return true
					})
			}),
			pAHVNr: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema.test('pAHVNrType', 'AHVNr ist erforderlich', function (value) {
						const rechtsform = this.parent.rechtsform
						if (rechtsform === 'einzelfirma' && !value) return false
						return true
					})
			}),
			pStrasseNr: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema.test('pStrasseNrType', 'StrasseNr ist erforderlich', function (value) {
						const rechtsform = this.parent.rechtsform
						if (rechtsform === 'einzelfirma' && !value) return false
						return true
					})
			}),
			pPlzOrt: yup.string().when('$step', {
				is: FormSteps.CUSTOMER,
				then: schema =>
					schema.test('pPlzOrtType', 'PlzOrt ist erforderlich', function (value) {
						const rechtsform = this.parent.rechtsform
						if (rechtsform === 'einzelfirma' && !value) return false
						return true
					})
			})
		}),
		orderForm: yup.object({
			lizenzChf: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Lizens/Mt ist erforderlich')
			}),
			initialisierungChf: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Initialisierung ist erforderlich')
			}),
			zenDesk: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Zen Desk ist erforderlich')
			}),
			schulung: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Schulung Digital ist erforderlich')
			}),
			gewünschterStart: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Gewünschter Start ist erforderlich')
			}),
			additionalServices: yup.string().when('$step', {
				is: FormSteps.ORDER,
				then: schema => schema.required('Additional Services ist erforderlich')
			})
		})
	})

	const [state, setState] = useState<StateType>({
		step: FormSteps.CUSTOMER,
		displayExitModal: false
	})
	const [contractLocation, setContractLocation] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [inputPassword, setInputPassword] = useState('')

	const [signature, setSignature] = useState({
		afrim: '',
		martin: '',
		customer1: '',
		customer2: ''
	})

	const [additionalDocument, setAdditionalDocument] = useState<FileList>()

	const handleFileUpload = (file: FileList) => {
		setAdditionalDocument(file)
	}
	const handlePassword = (value: string) => {
		setInputPassword(value)
	}

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		control
	} = useForm<HealthCornerForm>({
		resolver: yupResolver(schema as any),
		context: { step: state.step },
		mode: 'all'
	})

	const gewünschterStart = watch?.('orderForm.gewünschterStart')
	const lizenzChf = watch?.('orderForm.lizenzChf')
	const zenDesk = watch?.('orderForm.zenDesk')
	const nameDerFirma = watch?.('customerForm.nameDerFirma')
	const nameVorname = watch?.('customerForm.nameVorname')
	const vornameZweiterVorname = watch?.('customerForm.vornameZweiterVorname')
	const plzOrt = watch?.('customerForm.plzOrt')

	useEffect(() => {
		setContractData(() => ({
			gewünschterStart: gewünschterStart,
			lizenzChf: lizenzChf,
			zenDesk: zenDesk,
			nameDerFirma: nameDerFirma,
			nameVorname: nameVorname,
			vornameZweiterVorname: vornameZweiterVorname,
			plzOrt: plzOrt
		}))
	}, [gewünschterStart, lizenzChf, zenDesk, nameDerFirma, nameVorname, vornameZweiterVorname])

	const [contractData, setContractData] = useState({
		gewünschterStart: gewünschterStart,
		lizenzChf: lizenzChf,
		zenDesk: zenDesk,
		nameDerFirma: nameDerFirma,
		nameVorname: nameVorname,
		vornameZweiterVorname: vornameZweiterVorname,
		plzOrt: plzOrt
	})

	const handleContractDownload = () => {
		const newWindow = window.open()

		const rootElement = newWindow?.document.createElement('div')

		if (rootElement) {
			newWindow?.document.body.appendChild(rootElement)

			const ContractDocumentComponent = (
				<ContractDocument
					signature={signature}
					contractData={contractData}
					location={contractLocation}
				/>
			)

			const root = createRoot(rootElement)
			root.render(ContractDocumentComponent)
		}
	}

	const renderComponent = cond([
		[
			state.step === FormSteps.CUSTOMER,
			() => <CustomerForm {...{ watch, errors, register, control }} />
		],
		[state.step === FormSteps.ORDER, () => <OrderForm {...{ errors, register, control, watch }} />],
		[
			state.step === FormSteps.CONTRACT,
			() => (
				<ContractForm
					contractData={contractData}
					setSignature={setSignature}
					setLocation={setContractLocation}
				/>
			)
		],
		[state.step === FormSteps.COMPLETE, () => <CompleteForm />]
	])

	const nextStep = handleSubmit(data => {
		setState(prevState => ({
			...prevState,
			step: prevState.step + 1
		}))
	})

	const previousStep = () => {
		if (state.step === 1) {
			return
		}

		setState(prevState => ({
			...prevState,
			step: prevState.step - 1
		}))
	}

	let percentage = 0

	switch (state.step) {
		case FormSteps.CUSTOMER:
			percentage = 0
			break
		case FormSteps.ORDER:
			percentage = 50
			break
		case FormSteps.CONTRACT:
			percentage = 100
			break
		default:
			percentage = 100
	}

	const onSubmit = handleSubmit((data: HealthCornerForm) => {
		setShowModal(false)

		const payload = {
			...data,
			contractForm: {
				...data.contractForm,
				location: contractLocation,
				signature: {
					martin: signature.martin,
					afrim: signature.afrim,
					customer1: signature.customer1,
					customer2: signature.customer2
				}
			}
		}

		dispatch(form(payload)).then(() => setState({ ...state, step: FormSteps.COMPLETE }))
	})

	useEffect(() => {
		if (additionalDocument) {
			onSubmit()
		}
	}, [additionalDocument])

	return (
		<AppLayout>
			<div>
				{Password !== inputPassword ? (
					<PasswordScreen onChange={handlePassword} />
				) : (
					<form
						onSubmit={event => {
							if (state.step === FormSteps.CONTRACT) {
								onSubmit(event)
							} else {
								nextStep(event)
							}
						}}
						className="w-full font-bold">
						{state.step < FormSteps.COMPLETE && (
							<div className="bg-white py-16 px-28 pb-12 z-50 sticky top-0">
								<ProgressBar
									percent={percentage}
									filledBackground="linear-gradient(to right, rgb(153 246 228), rgb(20 184 166))">
									<Step transition="scale">
										{({ accomplished }: ProgressBarProps) => (
											<img
												style={{
													filter: `grayscale(${accomplished ? 0 : 100}%)`,
													backgroundColor: 'white',
													borderRadius: '100%'
												}}
												width="30"
												src={OneIcon}
											/>
										)}
									</Step>
									<Step transition="scale">
										{({ accomplished }: ProgressBarProps) => (
											<img
												style={{
													filter: `grayscale(${accomplished ? 0 : 100}%)`,
													backgroundColor: 'white',
													borderRadius: '100%'
												}}
												width="30"
												src={TwoIcon}
											/>
										)}
									</Step>
									<Step transition="scale">
										{({ accomplished }: ProgressBarProps) => (
											<img
												style={{
													filter: `grayscale(${accomplished ? 0 : 100}%)`,
													backgroundColor: 'white',
													borderRadius: '100%'
												}}
												width="30"
												src={ThreeIcon}
											/>
										)}
									</Step>
								</ProgressBar>
							</div>
						)}
						{showModal && (
							<Modal
								onClose={() => setShowModal(false)}
								title="Do you want to add any attachment?"
								showCrossIcon={true}>
								<div className="flex gap-x-6 justify-center items-center mt-4">
									<Button
										onClick={onSubmit}
										className="bg-red-500 rounded-md text-white hover:bg-red-600">
										No and Submit
									</Button>
									<FileUpload
										onUpload={handleFileUpload}
										name="additionalDocument"
										className="bg-green-500 hover:bg-green-600 text-white"
									/>
								</div>
							</Modal>
						)}
						<h1 className="px-32 py-8 text-5xl">Einfache Praxis- verwaltung und Online Termine</h1>

						<>{renderComponent}</>
						{state.step < FormSteps.COMPLETE && (
							<div className="fixed pb-8 pt-6 px-24 bottom-0 backdrop-blur-xl w-screen flex justify-between">
								{state.step > FormSteps.CUSTOMER && (
									<Button
										className="flex gap-x-2 items-center font-extrabold hover:text-black"
										type="button"
										onClick={previousStep}>
										<ArrowLeftIcon className="h-4 w-4" />
										<span>Go back</span>
									</Button>
								)}
								{state.step === FormSteps.CONTRACT ? (
									<>
										<Button
											onClick={handleContractDownload}
											disabled={Object.values(signature).some(s => s === '')}
											type="button"
											className="flex gap-x-2 font-extrabold hover:text-black">
											<ArrowDownOnSquareIcon className="h-5 w-5" />
											<span>Download license agreement in PDF</span>
										</Button>
										<Button
											onClick={() => setShowModal(true)}
											disabled={Object.values(signature).some(s => s === '')}
											type="button"
											className="flex items-center gap-x-2 font-extrabold hover:text-black">
											<ArrowRightIcon className="h-4 w-4" />
											<span>Submit Form</span>
										</Button>
									</>
								) : (
									<Button className="flex gap-x-2 items-center font-extrabold hover:text-black">
										<ArrowRightIcon className="h-4 w-4" />
										<span>
											{state.step === FormSteps.ORDER
												? 'Complete the order & create a license agreement'
												: 'Continue to the next step'}
										</span>
									</Button>
								)}
							</div>
						)}
					</form>
				)}
			</div>
		</AppLayout>
	)
}
