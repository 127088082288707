import axios from 'axios'
import { apiHost } from 'utils/host'

const sendForm = (data: HealthCornerForm) => {
	return axios
		.post(apiHost + '/form', data)
		.then(response => {
			return response.data
		})
		.catch(err => true)
}

const formService = {
	sendForm
}

export default formService
