import { Controller } from 'react-hook-form'

import { AppHeader } from 'components/app/header'
import { Input } from 'components/app/input'
import { Select } from 'components/app/select'
import { FormProps } from 'constants/constants'

export const OrderForm = ({ errors, control }: FormProps) => {
	return (
		<AppHeader number={2} heading="Bei Einzelfirmen Privatadresse">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-16 px-32 pt-0 mb-16">
				<Controller
					control={control}
					name={'orderForm.lizenzChf'}
					render={({ field: { onChange, value } }) => (
						<Select
							name="lizenzChf"
							labelText="Lizenz (24 months)"
							onChange={onChange}
							value={value}
							step="orderForm"
							errors={errors}>
							<option value="" className="hidden" />
							<option value={'Basic - CHF 149.00'}>Basic - CHF 149.00</option>
							<option value={'Professional - CHF 249.00'}>Professional - CHF 249.00</option>
							<option value={'Expert - CHF 449.00'}>Expert - CHF 449.00</option>
						</Select>
					)}
				/>
				<Controller
					control={control}
					name={'orderForm.initialisierungChf'}
					render={({ field: { onChange, value } }) => (
						<Select
							name="initialisierungChf"
							labelText="Initialisierung (one time)"
							onChange={onChange}
							value={value}
							step="orderForm"
							errors={errors}>
							<option value="" className="hidden" />
							<option value={'Basic - CHF 149.00'}>Basic - CHF 149.00</option>
							<option value={'Professional - CHF 249.00'}>Professional - CHF 249.00</option>
							<option value={'Expert - CHF 449.00'}>Expert - CHF 449.00</option>
						</Select>
					)}
				/>
				<Controller
					control={control}
					name={'orderForm.zenDesk'}
					render={({ field: { onChange, value } }) => (
						<Select
							name="zenDesk"
							labelText="ZenDesk (24 months)"
							onChange={onChange}
							value={value}
							step="orderForm"
							errors={errors}>
							<option value="" className="hidden" />
							<option value={'Digital - CHF 29.00'}>Digital - CHF 29.00</option>
							<option value={'Telephone - CHF 49.00'}>Telephone - CHF 49.00</option>
							<option value={'Digital & Telephone - CHF 69.00'}>
								Digital & Telephone - CHF 69.00
							</option>
						</Select>
					)}
				/>
				<Controller
					control={control}
					name={'orderForm.schulung'}
					render={({ field: { onChange, value } }) => (
						<Select
							name="schulung"
							labelText="Desired type of training"
							onChange={onChange}
							value={value}
							step="orderForm"
							errors={errors}>
							<option value="" className="hidden" />
							<option value="Digital - Free">Digital - Free</option>
							<option value="On site - offer">On site - offer</option>
						</Select>
					)}
				/>
				<Controller
					control={control}
					name={'orderForm.gewünschterStart'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="gewünschterStart"
							type="date"
							onChange={onChange}
							value={value}
							errors={errors}
							name="gewünschterStart"
							step="orderForm"
						/>
					)}
				/>
				<Controller
					control={control}
					name={'orderForm.additionalServices'}
					render={({ field: { onChange, value } }) => (
						<Select
							name="additionalServices"
							labelText="Additional Services?"
							onChange={onChange}
							value={value}
							step="orderForm"
							errors={errors}>
							<option value="" className="hidden" />
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</Select>
					)}
				/>

				<div className="flex flex-col gap-y-2 col-span-1 md:col-span-2 lg:col-span-3">
					<label htmlFor="customerForm.remarks">Remarks</label>
					<Controller
						control={control}
						name={'orderForm.remarks'}
						render={({ field: { onChange, value } }) => (
							<textarea
								className="focus:ring-0 border-none bg-gray-100 focus:bg-gray-200"
								onChange={onChange}
								rows={5}
								value={value}
								name="remarks"
							/>
						)}
					/>
				</div>
			</div>
		</AppHeader>
	)
}
