/**
 * Convert a string to title-case e.g. mustaqeem -> Mustaqeem,
 * mustaqeem tariq -> Mustaqeem Tariq
 * @param text String to be title-cased
 * @param splitBy This is to split and join the string if it's concatenated with any string literal e.g. space, hyphen etc
 */
export const toTitleCase = (text: string, splitBy = ' ', replace = false) => {
	if (!text) {
		return text
	}

	return text
		.trim()
		.toLowerCase()
		.split(splitBy)
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(replace ? ' ' : splitBy)
}

export const containsOnlyDigits = (text: string) => {
	return text.match(/^[0-9]+$/)
}
