import { Controller } from 'react-hook-form'

import { AppHeader } from 'components/app/header'
import { Input, InputNumber } from 'components/app/input'
import { Select } from 'components/app/select'
import { FormProps } from 'constants/constants'

export const CustomerForm = ({ watch, errors, control }: FormProps) => {
	const rechtsform = watch?.('customerForm.rechtsform')

	return (
		<AppHeader number={1} heading="Kundendetails">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-16 px-32 pt-0 mb-16">
				<Controller
					control={control}
					name={'customerForm.rechtsform'}
					render={({ field: { onChange, value } }) => (
						<Select
							labelText="Rechtsform"
							name="rechtsform"
							step="customerForm"
							errors={errors}
							onChange={onChange}
							value={value}>
							<option value="" className="hidden" />
							<option value="einzelfirma">Einzelfirma</option>
							<option value="gmbh">GmBH</option>
							<option value="ag">AG</option>
							<option value="andere">Andere</option>
						</Select>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.welche'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Welche"
							step="customerForm"
							onChange={onChange}
							disabled={rechtsform !== 'andere'}
							value={value}
							errors={errors}
							name="welche"
							className="md:col-span-2 lg:col-span-3 col-span-1"
						/>
					)}
				/>
				<Controller
					control={control}
					name={'customerForm.hrRegister'}
					render={({ field: { onChange, value } }) => (
						<Select
							labelText="HR Register"
							name="hrRegister"
							step="customerForm"
							errors={errors}
							onChange={onChange}
							value={value}>
							<option value="" className="hidden" />
							<option value="jA">JA</option>
							<option value="nein">NEIN</option>
						</Select>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.uidCheMwstNr'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="UID/CHE/Mwst.Nr"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="uidCheMwstNr"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.furArztpraxis'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Für Arztpraxis/Apotheke Konkordatsnummer: "
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="furArztpraxis"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.gLNBetrieb'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="GLN Betrieb"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="gLNBetrieb"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.nameDerFirma'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Name der Firma"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="nameDerFirma"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.nameVorname'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Name, Vorname:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="nameVorname"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.vornameZweiterVorname'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Name, Vorname:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="vornameZweiterVorname"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.nameUndNachname'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Name, Vorname:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="nameUndNachname"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.eMail'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="E-Mail:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="eMail"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.festnetznummer'}
					render={({ field: { onChange, value } }) => (
						<InputNumber
							step="customerForm"
							maxLength={15}
							labelText="Festnetznummer:"
							onChange={onChange}
							value={value}
							errors={errors}
							name="festnetznummer"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.mobile'}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<InputNumber
							maxLength={15}
							step="customerForm"
							onChange={e => onChange(e.target.value)}
							value={value}
							labelText="Mobile:"
							errors={errors}
							name="mobile"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.strasseNr'}
					render={({ field: { onChange, value } }) => (
						<Input
							step="customerForm"
							labelText="Strasse, Nr. (Firma):"
							onChange={onChange}
							value={value}
							errors={errors}
							name="strasseNr"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.plzOrt'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="PLZ, Ort (Firma)"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="plzOrt"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.webseiteOne'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Webseite 1:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="webseiteOne"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.webseiteTwo'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="Webseite 2:"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="webseiteTwo"
						/>
					)}
				/>

				<Controller
					control={control}
					name={'customerForm.heard'}
					render={({ field: { onChange, value } }) => (
						<Input
							labelText="How did you hear about us?"
							step="customerForm"
							onChange={onChange}
							value={value}
							errors={errors}
							name="heard"
						/>
					)}
				/>
				<div className="flex flex-col gap-y-2 md:col-span-2 lg:col-span-3 col-span-1">
					<label htmlFor="customerForm.remarks">Remarks</label>
					<Controller
						control={control}
						name={'customerForm.remarks'}
						render={({ field: { onChange, value } }) => (
							<textarea
								className="focus:ring-0 border-none bg-gray-100 focus:bg-gray-200"
								onChange={onChange}
								rows={5}
								value={value}
								name="remarks"
							/>
						)}
					/>
				</div>
				{rechtsform === 'einzelfirma' && (
					<>
						<Controller
							control={control}
							name={'customerForm.pGeburtsdatum'}
							render={({ field: { onChange, value } }) => (
								<Input
									labelText="Geburtsdatum"
									type="date"
									onChange={onChange}
									value={value}
									errors={errors}
									name="pGeburtsdatum"
									step="customerForm"
								/>
							)}
						/>
						<div>
							<Controller
								control={control}
								name={'customerForm.pAHVNr'}
								render={({ field: { onChange, value } }) => (
									<InputNumber
										labelText="AHV-Nr:"
										step="customerForm"
										onChange={onChange}
										value={value}
										errors={errors}
										name="pAHVNr"
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name={'customerForm.pStrasseNr'}
								render={({ field: { onChange, value } }) => (
									<InputNumber
										labelText="Strasse, Nr. (Firma):"
										step="customerForm"
										onChange={onChange}
										value={value}
										errors={errors}
										name="pStrasseNr"
									/>
								)}
							/>
						</div>
						<div>
							<Controller
								control={control}
								name={'customerForm.pPlzOrt'}
								render={({ field: { onChange, value } }) => (
									<InputNumber
										labelText="PLZ, Ort (Firma)"
										step="customerForm"
										onChange={onChange}
										value={value}
										errors={errors}
										name="pPlzOrt"
									/>
								)}
							/>
						</div>
					</>
				)}
			</div>
		</AppHeader>
	)
}
