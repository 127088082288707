import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 1000
		}
	]
})

const styles = StyleSheet.create({
	page: {
		backgroundColor: 'white',
		color: 'black',
		fontFamily: 'Open Sans',
		flexDirection: 'column',
		paddingTop: '60',
		paddingBottom: '60'
	},
	section: {
		margin: 10,
		padding: 10,
		fontSize: '10px'
	},
	viewer: {
		width: window.innerWidth,
		height: window.innerHeight
	},
	signature: {
		height: 50,
		width: 130,
		borderBottom: '1px solid black'
	},
	logo: {
		height: 16,
		width: 120,
		marginLeft: 20
	},
	text: {
		marginLeft: 18,
		fontSize: '10px',
		marginTop: 10
	}
})

interface ContractDocumentProps {
	signature: {
		afrim: string
		martin: string
		customer1: string
		customer2: string
	}
	contractData: {
		gewünschterStart: string
		lizenzChf: string
		zenDesk: string
		nameDerFirma: string
		nameVorname: string
		vornameZweiterVorname: string
		plzOrt: string
	}
	location: string
}

export const ContractDocument = ({ signature, location, contractData }: ContractDocumentProps) => {
	return (
		<PDFViewer style={styles.viewer}>
			<Document title="Contract.pdf">
				<Page size="A4" style={styles.page}>
					<Image src={'/images/logo.png'} style={[styles.logo, { marginTop: '-20' }]} />
					<View style={styles.section}>
						<Text
							style={{
								textAlign: 'center',
								fontWeight: 'extrabold',
								fontSize: '25px'
							}}>
							Bestellung abschliessen & Lizenzvertrag erstellen
						</Text>
						<Text
							style={{
								textAlign: 'center',
								marginTop: '30',
								fontWeight: 'extrabold',
								fontSize: '15px'
							}}>
							zwischen
						</Text>
					</View>
					<View style={styles.section}>
						<Text>
							<Text style={{ fontWeight: 'extrabold' }}>
								Resolut Technologies GmbH (UID-375.534.221),
							</Text>
							Gesellschaft mit beschränkter Haftung mit Sitz in 6052 Hergiswil/NW, Pilatusstrasse
							28, handelnd durch die laut Handelsregistereintrag kollektivzeichnungsberechtigten
							Geschäftsführer Afrim Demiri, von Pfaffnau, in Pfaffnau und Hans Martin Bindschedler,
							von Thalwil, in Zürich
						</Text>
					</View>
					<View style={styles.section}>
						<Text
							style={{
								fontWeight: 'extrabold',
								textDecoration: 'underline',
								textAlign: 'right',
								marginBottom: 20
							}}>
							Lizenzgeberin
						</Text>
						<Text style={{ textAlign: 'center' }}>und</Text>
					</View>
					<Text
						style={{
							borderBottom: '1px solid black',
							width: '120',
							fontSize: '13px',
							textAlign: 'center',
							marginLeft: '20'
						}}>
						{contractData.nameDerFirma}
					</Text>

					<Text style={styles.text}>
						handelnd durch die im Kontaktformular zeichnungsberechtigten Personen
					</Text>
					<View style={styles.section}>
						<Text
							style={{
								textAlign: 'right',
								fontWeight: 'extrabold',
								textDecoration: 'underline'
							}}>
							Licensee (business customer)
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ lineHeight: '1.5px' }}>
							Die Parteien schliessen einen Lizenzvertrag mit den folgenden Bestimmungen ab, als
							Vertragsergänzung akzeptieren die Parteien die aktuellen Allgemeinen
							Geschäftsbedingungen (AGB) und deren Anhänge der Lizenzgeberin.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>1. Gegenstand der Lizenz</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							1.1 Nutzungslizenz für HealthCorner Pro Software und die HealthCorner Plattform.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>2. Art und Umfang der Lizenz</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							2.1 Die Lizenzgeberin gewährt der Lizenznehmerin eine einfache Lizenz.
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '6' }}>
							2.2 Gewähltes Produkt (aktuelle Preise siehe AGB, «Anhang I- Preisliste»)
						</Text>
						<Text style={{ paddingLeft: '25', marginTop: '6' }}>
							2.2.a {contractData.lizenzChf}
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>
							3. Initialisierungskosten und Lizenzgebühren
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12', lineHeight: '1.5px' }}>
							3.1 Die Lizenznehmerin schuldet der Lizenzgeberin für das Zurverfügungstellen des
							Lizenzgegenstandes eine Entschädigung für die Initialisierungskosten und monatlichen
							Lizenzgebühren, erstmals per
							{contractData.gewünschterStart} nach Massgabe der AGB.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>
							4. Wartung und Support am Lizenzgegenstand sowie Orientierungsobliegenheit
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							4.1 Die Lizenzgeberin nimmt Wartungen auf der Plattform sowie an der Software vor, um
							den Betrieb des Lizenzgegenstandes aufrecht zu erhalten.
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '6' }}>
							4.2 Die Lizenznehmerin wählt optional folgenden Supportdienst(siehe AGB, «Anhang I-
							Preisliste»)
						</Text>
						<Text style={{ paddingLeft: '25', marginTop: '6' }}>4.2.a {contractData.zenDesk}</Text>
						<Text style={{ paddingLeft: '10', marginTop: '6' }}>
							4.3 Der Lizenznehmerin trifft die Obliegenheit, die Lizenzgeberin sofort zu
							informieren, wenn sie von Urheberrechtsverletzungen erfährt.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>5. Beginn, Dauer und Beendigung</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							5.1 Dieser Vertrag tritt am {contractData.gewünschterStart} in Kraft und wird auf
							unbestimmte Zeit mit einer Mindestlaufzeit von 24 Monaten abgeschlossen.
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '6' }}>
							5.2 Betreffend die Vorgehensweise zur Beendigung dieses Vertrages wird auf die
							Bestimmungen in den AGB verwiesen.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>6. Geheimhaltung</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							6.1 Die Lizenznehmerin verpflichtet sich, die überlassenen Mitteilungen jeglicher Art
							aus diesem Vertrag auch nach Vertragsauflösung geheim zu halten. Sie haftet der
							Lizenzgeberin für allfällige Schäden aus der Geheimnispflichtverletzung auch ohne
							Verschulden.
						</Text>
					</View>
					<View style={styles.section}>
						<Text style={{ fontWeight: 'extrabold' }}>7. Übertragbarkeit</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							7.1 Die Lizenzgeberin ist einseitig berechtigt, die Rechte und Pflichten aus diesem
							Vertrag auf ein Drittunternehmen zu übertragen. Die Lizenznehmerin nimmt diese
							Möglichkeit zustimmend zur Kenntnis.
						</Text>
						<Text style={{ paddingLeft: '10', marginTop: '12' }}>
							7.2 Die Parteien haben diesen zweiseitigen Vertrag gelesen und erklären sich mit
							dessen Inhalt einverstanden:
						</Text>
					</View>
					<View
						break
						style={[styles.section, { flexDirection: 'row', justifyContent: 'space-between' }]}>
						<Text>Hergiswil, {moment(new Date()).format('DD MMMM YYYY')}</Text>

						<View
							style={{
								flexDirection: 'row'
							}}>
							<Text>Ort, </Text>
							<Text
								style={{
									borderBottom: '1px solid black',
									width: '100px',
									textAlign: 'center'
								}}>
								{contractData.plzOrt}
							</Text>
							<Text>{moment(new Date()).format('DD MMMM YYYY')}</Text>
						</View>
					</View>
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'space-between',
							fontSize: '10px'
						}}>
						<Text style={{ fontWeight: 'extrabold', paddingLeft: 20, marginTop: '-12' }}>
							Die Lizenzgeberin
						</Text>
						<Text style={{ fontWeight: 'extrabold', paddingRight: 23, marginTop: '-12' }}>
							Die Lizenznehmerin
						</Text>
					</View>
					<View
						style={[
							styles.section,
							{
								marginTop: '50px',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}
						]}>
						<Text style={{ fontWeight: 'extrabold' }}>Resolut Technologies GmbH</Text>
						<Text
							style={{
								fontWeight: 'extrabold'
							}}>
							{contractData.nameDerFirma}
						</Text>
					</View>
					<View
						style={[
							styles.section,
							{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }
						]}>
						<View style={{ flexDirection: 'column' }}>
							{signature.afrim ? (
								<Image style={styles.signature} src={signature.afrim} />
							) : (
								<Text style={{ marginBottom: '10px' }}>_______________________________</Text>
							)}
							<Text
								style={{
									fontWeight: 'extrabold',
									marginLeft: 'auto',
									marginRight: 'auto',
									fontSize: '10px'
								}}>
								Afrim
							</Text>
						</View>
						<View style={{ flexDirection: 'column' }}>
							{signature.customer1 ? (
								<Image style={styles.signature} src={signature.customer1} />
							) : (
								<Text style={{ marginBottom: '10px' }}>_______________________________</Text>
							)}
							<Text
								style={{
									fontWeight: 'extrabold',
									marginLeft: 'auto',
									marginRight: 'auto',
									fontSize: '10px'
								}}>
								{contractData.nameVorname}
							</Text>
						</View>
					</View>
					<View
						style={[
							styles.section,
							{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }
						]}>
						<View style={{ flexDirection: 'column' }}>
							{signature.martin ? (
								<Image style={styles.signature} src={signature.martin} />
							) : (
								<Text style={{ marginBottom: '10px' }}>_______________________________</Text>
							)}
							<Text
								style={{
									fontWeight: 'extrabold',
									marginLeft: 'auto',
									marginRight: 'auto',
									fontSize: '10px'
								}}>
								Martin
							</Text>
						</View>
						<View style={{ flexDirection: 'column' }}>
							{signature.customer2 ? (
								<Image style={styles.signature} src={signature.customer2} />
							) : (
								<Text>_______________________________</Text>
							)}
							<Text
								style={{
									fontWeight: 'extrabold',
									marginTop: '10px',
									marginLeft: 'auto',
									marginRight: 'auto',
									fontSize: '10px'
								}}>
								{contractData.vornameZweiterVorname}
							</Text>
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	)
}
