import { CheckCircleIcon } from '@heroicons/react/24/outline'

export const CompleteForm = () => {
	return (
		<div className="h-screen flex flex-col justify-center items-center gap-y-1 text-3xl">
			<CheckCircleIcon className="h-20 w-20 fill-green-500 stroke-white" />
			<p>Form has been submitted successfully</p>
		</div>
	)
}
