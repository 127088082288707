import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import 'react-step-progress-bar/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/main.css'

import Routing from 'routing'
import store from 'store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const persistor = persistStore(store)
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ToastContainer pauseOnFocusLoss={false} />
				<Routing />
			</PersistGate>
		</Provider>
	</React.StrictMode>
)
