import clsx from 'clsx'
import React, { InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	labelText?: string
	className?: string
	onUpload?: (files: FileList) => void
}

export default function FileUpload({ name, className, labelText, onUpload }: InputProps) {
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (!files) return
		onUpload?.(files)
	}

	return (
		<div>
			{labelText && (
				<label htmlFor={name} className="block text-[#0D0C18]">
					{labelText}
				</label>
			)}
			<div className="relative">
				<input
					type="file"
					className="hidden w-full appearance-none"
					accept=".pdf"
					name={name}
					onChange={handleInputChange}
				/>
				<button
					className={clsx('cursor-pointer font-bold  rounded-md py-3 px-5', className)}
					onClick={() => {
						const fileInput = document.querySelector(`[name=${name}]`) as HTMLInputElement
						fileInput.click()
					}}>
					Add Attachment
				</button>
			</div>
		</div>
	)
}
