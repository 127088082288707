import { CalendarDaysIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import moment from 'moment'
import { InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string
	className?: string
	onDateChange?: (value: string, name: string) => void
}

export const InputDate = ({
	value,
	name,
	label,
	placeholder,
	className,
	onDateChange,
	...props
}: InputProps) => {
	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target
		if (value === '') {
			return onDateChange?.('', name)
		}
		const selectedDate = moment(event.target.value).format('MMM DD, YYYY')
		onDateChange?.(selectedDate, name)
	}
	return (
		<div className="w-fit inline-block mx-2">
			{label && (
				<label htmlFor={name} className="text-sm text-strapline">
					{label}
				</label>
			)}
			<div className="relative flex items-center">
				<input
					{...props}
					type="date"
					name={name}
					onChange={handleDateChange}
					className={clsx('opacity-0 absolute z-50 pr-0 input-cursor-pointer', className)}
				/>
				<input
					{...props}
					type="text"
					disabled
					value={value}
					placeholder={placeholder}
					className={clsx('font-semibold', className)}
				/>
				<div className="absolute inset-y-auto w-full flex justify-end">
					<CalendarDaysIcon className="h-5 w-5" aria-hidden="true" />
				</div>
			</div>
		</div>
	)
}
