import clsx from 'clsx'

interface ButtonProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	children: React.ReactNode
}

export const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
	return (
		<button
			{...props}
			className={clsx(
				className,
				' py-3 px-5 font-bold focus:outline-none focus:ring-0',

				{
					'pointer-events-none cursor-not-allowed text-gray-400': props.disabled
				}
			)}>
			{children}
		</button>
	)
}
