import moment from 'moment'
import 'moment/locale/de'
import React, { useEffect, useState } from 'react'

import { InputDate } from 'components/app/date'
import { AppHeader } from 'components/app/header'
import { Signature } from 'components/app/signature'
import { FormProps } from 'constants/constants'
interface ContractFormProps extends FormProps {
	setLocation: React.Dispatch<React.SetStateAction<string>>
	setSignature: React.Dispatch<
		React.SetStateAction<{
			afrim: string
			martin: string
			customer1: string
			customer2: string
		}>
	>
	contractData: {
		gewünschterStart: string
		lizenzChf: string
		zenDesk: string
		nameDerFirma: string
		nameVorname: string
		plzOrt: string
		vornameZweiterVorname: string
	}
}

export const ContractForm = ({ contractData, setSignature }: ContractFormProps) => {
	moment.locale('de')
	const date = moment(new Date()).format('DD. MMMM YYYY')

	const [isSmallScreen, setIsSmallScreen] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 768) // Adjust the breakpoint as needed
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	return (
		<AppHeader number={3} heading="Lizenzvertrag">
			<div className="py-16 px-32 pt-0 text-lg space-y-4 mb-16">
				<h4 className="text-center text-2xl">zwischen</h4>

				<p className="font-light">
					<span className="font-bold"> Resolut Technologies GmbH (UID-375.534.221), </span>
					Gesellschaft mit beschränkter Haftung mit Sitz in 6052 Hergiswil/NW, Pilatusstrasse 28,
					handelnd durch die laut Handelsregistereintrag kollektivzeichnungsberechtigten
					Geschäftsführer Afrim Demiri, von Pfaffnau, in Pfaffnau und Hans Martin Bindschedler, von
					Thalwil, in Zürich
				</p>
				<p className="font-bold text-right underline">Lizenzgeberin</p>
				<p className="font-light text-center">und</p>
				<div>
					<p className="border-b lg:w-96 md:w-76 w-56 border-black text-center">
						{contractData.nameDerFirma}
					</p>
				</div>
				<p className="font-light">
					handelnd durch die im Kontaktformular zeichnungsberechtigten Personen
				</p>
				<p style={{ marginBottom: '100px' }} className="font-bold text-right underline">
					Lizenznehmerin (Geschäftskunde)
				</p>
				<p className="font-light">
					Die Parteien schliessen einen Lizenzvertrag mit den folgenden Bestimmungen ab, als
					Vertragsergänzung akzeptieren die Parteien die aktuellen Allgemeinen Geschäftsbedingungen
					(AGB) und deren Anhänge der Lizenzgeberin.
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">1.</span>Gegenstand der Lizenz
					</div>
					<span className="pl-10 font-light mt-1">
						<div>
							<span className="mr-6">1.1</span> Nutzungslizenz für HealthCorner Pro Software und die
							HealthCorner Plattform.
						</div>
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">2.</span> Art und Umfang der Lizenz
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">2.1</span> Die Lizenzgeberin gewährt der Lizenznehmerin eine
						einfache Lizenz.
					</span>
					<span className="pl-10 font-light">
						<span className="mr-6">2.2</span> Gewähltes Produkt (aktuelle Preise siehe AGB, «Anhang
						I- Preisliste»)
					</span>
					<span className="pl-24 font-light">
						<span className="mr-6">2.2.a</span> {contractData.lizenzChf}
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">3.</span> Initialisierungskosten und Lizenzgebühren
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">3.1</span> Die Lizenznehmerin schuldet der Lizenzgeberin für das
						Zurverfügungstellen des Lizenzgegenstandes eine Entschädigung für die
						Initialisierungskosten und monatlichen Lizenzgebühren, erstmals per
						<InputDate
							className="w-56 text-lg pb-0 focus:ring-0 border-b border-t-0 border-l-0 border-r-0 border-black"
							name="gewünschterStart"
							disabled
							required
							value={contractData.gewünschterStart}
						/>
						nach Massgabe der AGB.
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">4.</span> Wartung und Support am Lizenzgegenstand sowie
						Orientierungsobliegenheit
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">4.1</span> Die Lizenzgeberin nimmt Wartungen auf der Plattform
						sowie an der Software vor, um den Betrieb des Lizenzgegenstandes aufrecht zu erhalten.
					</span>
					<span className="pl-10 font-light">
						<span className="mr-6">4.2</span> Die Lizenznehmerin wählt optional folgenden
						Supportdienst(siehe AGB, «Anhang I- Preisliste»)
					</span>
					<span className="pl-24 font-light">
						<span className="mr-6">4.2.a</span> {contractData.zenDesk}
					</span>
					<span className="pl-10 font-light">
						<span className="mr-6">4.3</span> Der Lizenznehmerin trifft die Obliegenheit, die
						Lizenzgeberin sofort zu informieren, wenn sie von Urheberrechtsverletzungen erfährt.
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">5.</span> Beginn, Dauer und Beendigung
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">5.1</span> Dieser Vertrag tritt am
						<InputDate
							className="w-56 text-lg pb-0 focus:ring-0 border-b border-t-0 border-l-0 border-r-0 border-black"
							name="gewünschterStart"
							required
							disabled
							value={contractData.gewünschterStart}
						/>
						in Kraft und wird auf unbestimmte Zeit mit einer Mindestlaufzeit von 24 Monaten
						abgeschlossen.
					</span>
					<span className="pl-10 font-light">
						<span className="mr-6">5.2</span> Betreffend die Vorgehensweise zur Beendigung dieses
						Vertrages wird auf die Bestimmungen in den AGB verwiesen.
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">6.</span> Geheimhaltung
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">6.1</span> Die Lizenznehmerin verpflichtet sich, die überlassenen
						Mitteilungen jeglicher Art aus diesem Vertrag auch nach Vertragsauflösung geheim zu
						halten. Sie haftet der Lizenzgeberin für allfällige Schäden aus der
						Geheimnispflichtverletzung auch ohne Verschulden.
					</span>
				</p>
				<p className="flex flex-col">
					<div>
						<span className="mr-6">7.</span> Übertragbarkeit
					</div>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">7.1</span> Die Lizenzgeberin ist einseitig berechtigt, die Rechte
						und Pflichten aus diesem Vertrag auf ein Drittunternehmen zu übertragen. Die
						Lizenznehmerin nimmt diese Möglichkeit zustimmend zur Kenntnis.
					</span>
					<span className="text-center">***</span>
					<span className="pl-10 font-light mt-1">
						<span className="mr-6">7.2</span> Die Parteien haben diesen zweiseitigen Vertrag gelesen
						und erklären sich mit dessen Inhalt einverstanden:
					</span>
				</p>
				<div
					className="flex justify-between items-center text-center"
					style={{ marginTop: '30px' }}>
					<p>
						Hergiswil, <span className="text-red-500 lg:ml-2 block lg:inline">{date}</span>
					</p>
					<p>
						Ort,
						<input
							type="text"
							disabled
							required
							className="w-40 ml-2 text-center text-lg pb-0 focus:ring-0 border-b border-t-0 border-l-0 border-r-0 border-black"
							name="ort"
							value={contractData.plzOrt}
						/>
						<span className="text-red-500 lg:ml-2 block lg:inline">{date}</span>
					</p>
				</div>
				<div className="flex justify-between items-center" style={{ marginTop: '30px' }}>
					<p>Die Lizenzgeberin</p>
					<p>Die Lizenznehmerin</p>
				</div>
				<div style={{ marginTop: '50px' }} className="flex justify-between items-center">
					Resolut Technologies GmbH <p>{contractData.nameDerFirma}</p>
				</div>
				<div className="flex justify-between items-start" style={{ marginTop: '30px' }}>
					<div className="flex flex-col gap-y-4">
						<Signature name="afrim" width={isSmallScreen ? 200 : 256} setSignature={setSignature} />
						<p className="font-light text-center">Afrim Demiri</p>
					</div>
					<div className="flex flex-col gap-y-4">
						<Signature
							name="customer1"
							width={isSmallScreen ? 200 : 256}
							setSignature={setSignature}
						/>
						<p className="font-light text-center">{contractData.nameVorname}</p>
					</div>
				</div>
				<div className="flex justify-between items-start" style={{ marginTop: '30px' }}>
					<div className="flex flex-col gap-y-4">
						<Signature
							name="martin"
							width={isSmallScreen ? 200 : 256}
							setSignature={setSignature}
						/>
						<p className="font-light text-center">Hans Martin Bindschedler</p>
					</div>
					<div className="flex flex-col gap-y-4">
						<Signature
							name="customer2"
							width={isSmallScreen ? 200 : 256}
							setSignature={setSignature}
						/>
						<p className="font-light text-center">{contractData.vornameZweiterVorname}</p>
					</div>
				</div>
			</div>
		</AppHeader>
	)
}
