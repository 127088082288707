import clsx from 'clsx'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import React from 'react'

interface AppLayoutProps {
	children: React.ReactNode
	title?: string
	renderHeader?: boolean
	docTitle?: boolean
	className?: string
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	children,
	title,
	renderHeader = true,
	className
}) => {
	const docTitleText = title ? `${title} | HealthCorner` : 'HealthCorner'
	useDocumentTitle(docTitleText)

	return (
		<div className={clsx('w-full text-slate-700', className)}>
			{renderHeader && (
				<div className="flex justify-between items-center pt-7 px-12">
					<img
						src={'/images/logo.png'}
						alt="logo"
						height={200}
						width={200}
						className="fill-blue-500 pl-10"
					/>
					<h1 className="font-bold text-center lg:text-xl text-sm text-blue-600">
						Stammdatenblatt/Bestellung (Kunde, Gesundheitsfachperson)
					</h1>
				</div>
			)}
			{children}
		</div>
	)
}
