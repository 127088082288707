import { Password } from 'constants/constants'
import React, { useState } from 'react'

interface PasswordScreenProps {
	onChange: (value: string) => void
}

export const PasswordScreen = ({ onChange }: PasswordScreenProps) => {
	const [inputValue, setInputValue] = useState('')
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onChange(inputValue)
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value)
	}
	return (
		<div className="py-16 px-16 pt-6 mb-16">
			<p>
				Dieser Inhalt ist passwortgeschützt. Um ihn anzuschauen, geben Sie bitte Ihr Passwort unten
				ein:
			</p>
			<div className="pt-2">
				<input
					className="lg:w-96 w-56 appearance-none py-3 font-normal border-none bg-gray-100 focus:bg-gray-200 focus:ring-0 text-gray-800 outline-none placeholder-gray-400 rounded-lg focus:outline-none border-gray-500 text-md"
					name="password"
					type="password"
					onChange={handleChange}
					onKeyDown={handleKeyDown}
				/>
			</div>
			{inputValue && inputValue !== Password && (
				<p className="text-red-500 mt-2">Falsches Passwort</p>
			)}
		</div>
	)
}
